@font-face {
  font-family: "proxima-nova";
  src: url("../../fonts/proxima/proxima900") format("woff2"),
    url("../../fonts/proxima/proxima900woff2.woff") format("woff"),
    url("../../fonts/proxima/proxima900woff") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}
@font-face {
  font-family: "proxima-nova";
  src: url("../../fonts/proxima/proxima900italic") format("woff2"),
    url("../../fonts/proxima/proxima900italicwoff2.woff") format("woff"),
    url("../../fonts/proxima/proxima900italicwoff") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
}
@font-face {
  font-family: "proxima-nova";
  src: url("../../fonts/proxima/proxima700") format("woff2"),
    url("../../fonts/proxima/proxima700woff2.woff") format("woff"),
    url("../../fonts/proxima/proxima700woff") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}
@font-face {
  font-family: "proxima-nova";
  src: url("../../fonts/proxima/proxima700italic") format("woff2"),
    url("../../fonts/proxima/proxima700italicwoff2.woff") format("woff"),
    url("../../fonts/proxima/proxima700italicwoff") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}
@font-face {
  font-family: "proxima-nova";
  src: url("../../fonts/proxima/proxima800") format("woff2"),
    url("../../fonts/proxima/proxima800woff2.woff") format("woff"),
    url("../../fonts/proxima/proxima800woff") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
}
@font-face {
  font-family: "proxima-nova";
  src: url("../../fonts/proxima/proxima800italic") format("woff2"),
    url("../../fonts/proxima/proxima800italicwoff2.woff") format("woff"),
    url("../../fonts/proxima/proxima800italicwoff") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 800;
  font-stretch: normal;
}
@font-face {
  font-family: "proxima-nova";
  src: url("../../fonts/proxima/proxima100") format("woff2"),
    url("../../fonts/proxima/proxima100woff2.woff") format("woff"),
    url("../../fonts/proxima/proxima100woff") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 100;
  font-stretch: normal;
}
@font-face {
  font-family: "proxima-nova";
  src: url("../../fonts/proxima/proxima100italic") format("woff2"),
    url("../../fonts/proxima/proxima100italicwoff2.woff") format("woff"),
    url("../../fonts/proxima/proxima100italicwoff") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 100;
  font-stretch: normal;
}
@font-face {
  font-family: "proxima-nova";
  src: url("../../fonts/proxima/proxima600") format("woff2"),
    url("../../fonts/proxima/proxima600woff2.woff") format("woff"),
    url("../../fonts/proxima/proxima600woff") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}
@font-face {
  font-family: "proxima-nova";
  src: url("../../fonts/proxima/proxima600italic") format("woff2"),
    url("../../fonts/proxima/proxima600italicwoff2.woff") format("woff"),
    url("../../fonts/proxima/proxima600italicwoff") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 600;
  font-stretch: normal;
}
@font-face {
  font-family: "proxima-nova";
  src: url("../../fonts/proxima/proxima400") format("woff2"),
    url("../../fonts/proxima/proxima400woff2.woff") format("woff"),
    url("../../fonts/proxima/proxima400woff") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}
@font-face {
  font-family: "proxima-nova";
  src: url("../../fonts/proxima/proxima400italic") format("woff2"),
    url("../../fonts/proxima/proxima400italicwoff2.woff") format("woff"),
    url("../../fonts/proxima/proxima400italicwoff") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}
@font-face {
  font-family: "proxima-nova";
  src: url("../../fonts/proxima/proxima300") format("woff2"),
    url("../../fonts/proxima/proxima300woff2.woff") format("woff"),
    url("../../fonts/proxima/proxima300woff") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}
@font-face {
  font-family: "proxima-nova";
  src: url("../../fonts/proxima/proxima300italic") format("woff2"),
    url("../../fonts/proxima/proxima300italicwoff2.woff") format("woff"),
    url("../../fonts/proxima/proxima300italicwoff") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
}
@font-face {
  font-family: "proxima-nova";
  src: url("../../fonts/proxima/proxima500") format("woff2"),
    url("../../fonts/proxima/proxima500woff2.woff") format("woff"),
    url("../../fonts/proxima/proxima500woff") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}
@font-face {
  font-family: "proxima-nova";
  src: url("../../fonts/proxima/proxima500italic") format("woff2"),
    url("../../fonts/proxima/proxima500italicwoff2.woff") format("woff"),
    url("../../fonts/proxima/proxima500italicwoff") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
}
body {
  font-family: "proxima-nova", sans-serif !important;
}
