// LAYOUT DESIGN CHANGE
@media (min-width: 992px) {
  .app-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
@media (max-width: 991.98px) {
  .app-container {
    max-width: none;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
#kt_app_header_container {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
// Fonts
.fs-14 {
  font-size: 1.077rem !important;
}
.fs-15 {
  font-size: 1.154rem !important;
}
.fs-16 {
  font-size: 1.231rem !important;
}
.fs-18 {
  font-size: 1.385rem !important;
}
.fs-20 {
  font-size: 1.538rem !important;
}
.fs-22 {
  font-size: 1.692rem !important;
}
.fs-24 {
  font-size: 1.846rem !important;
}
.fs-26 {
  font-size: 2rem !important;
}
.fs-28 {
  font-size: 2.154rem !important;
}
.fs-30 {
  font-size: 2.308rem !important;
}
.fs-32 {
  font-size: 2.462rem !important;
}
.fs-34 {
  font-size: 2.615rem !important;
}
.fs-35 {
  font-size: 2.692rem !important;
}
.fs-36 {
  font-size: 2.769rem !important;
}
.fs-38 {
  font-size: 2.923rem !important;
}
.fs-40 {
  font-size: 3.077rem !important;
}
.fs-42 {
  font-size: 3.231rem !important;
}
.fs-44 {
  font-size: 3.385rem !important;
}
.fs-46 {
  font-size: 3.538rem !important;
}
.fs-48 {
  font-size: 3.692rem !important;
}
// Font Weight
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}
// Font Style
.text-gray {
  color: #7c7c7c !important;
}
.text-black {
  color: #1a1a1a !important;
}
.text-italic {
  font-style: italic !important;
}
// Custom Background Color
.bg-white {
  background-color: #ffff !important;
}
// STEPPER CSS
.stepperInput {
  display: flex;
  .stepperInput__input {
    border-left: 0;
    border-right: 0;
    width: 55px;
    height: 40px;
    text-align: center;
    border: solid 0.5px #e0e0df;
  }
  .button {
    cursor: pointer;
    padding: 5px 15px;
    color: #ffffff;
    background-color: #1b74e4;
    font-size: 1.231rem;
    font-weight: bolder;
    border: none;
    border-radius: 7px;
  }
  .button--addOnLeft {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .button--addOnRight {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input {
    border: solid 0.5px #e0e0df;
    border-radius: 0;
    color: #1a1a1a;
  }
}
// Custom Carousel
.carousel-custom .carousel-indicators.carousel-indicators-dots li.active:after {
  background-color: #1b74e4;
}
.carousel-custom .carousel-indicators.carousel-indicators-dots li:after {
  background-color: #b3c7f5;
}
// Custom form-control
.form-control-custom {
  background-color: #f9f9f9;
  color: #1a1a1a;
  transition: color 0.2s ease;
  min-height: calc(1.88em + 1.533rem + 10px);
  padding: 0.825rem 1.5rem;
  font-size: 1.231rem;
  font-weight: 600;
  border-radius: 8px;
  border: solid 0.5px #e0e0df;
}
.form-control-custom:focus,
.form-control-custom:active {
  background-color: #f9f9f9 !important;
}
.form-control-table {
  font-size: 1.154rem;
  font-weight: 600;
  color: #1a1a1a;
  padding: 0.79rem 1.077rem;
  border-radius: 5px;
  min-height: calc(1rem + 1rem + 10px);
  border: solid 0.5px #e0e0df;
  background-color: #f9f9f9;
  transition: color 0.2s ease;
}
// Cutom Button
.btn {
  font-size: 1.231rem;
  font-weight: bold;
}
.auth-footer {
  position: absolute;
  bottom: 0%;
  left: 50%;
  margin-bottom: 10px;
  transform: translate(-50%, 0%);
}
.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.play-btn:hover {
  transition: 0.2s all ease-in;
}
@media (min-width: 992px) {
  .auth-footer-2 {
    position: absolute;
    bottom: -50%;
    left: 50%;
    margin-bottom: 10px;
    transform: translate(-50%, -0%);
  }
}
@media (max-width: 576px) {
  .br {
    display: none;
  }
  .custom-alert {
    padding: 15px;
    .br {
      display: none !important;
    }
    .custom-alert {
      padding: 15px;
      border-radius: 8px;
    }
    .custom-alert h2 {
      font-size: 18px;
      font-weight: bold;
      color: #1a1a1a;
    }
    .custom-alert p {
      font-size: 15px;
      font-weight: 500;
      color: #1a1a1a;
    }
  }
  @media (max-width: 992px) {
    .br-md {
      display: none;
    }
  }
  .otp-group {
    display: flex;
    width: 100%;
    max-width: 360px;
    column-gap: 10px;
    margin: 0 15px;
  }
  .otp-input {
    width: 100%;
    height: 60px;
    border: solid 0.5px #f9f9f9 !important;
    background-color: #f9f9f9 !important;
    border-radius: 8px;
  }
  .custom-alert h2 {
    font-size: 18px;
    font-weight: bold;
    color: #1a1a1a;
  }
  .custom-alert p {
    font-size: 15px;
    font-weight: 500;
    color: #1a1a1a;
  }
}
@media (max-width: 992px) {
  .br-md {
    display: none;
  }
}
//otp-input
.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
  margin: 0 15px;
}
.otp-input {
  width: 100%;
  height: 60px;
  border: solid 0.5px #f9f9f9 !important;
  background-color: #f9f9f9 !important;
  border-radius: 8px;
  text-align: center;
  line-height: 1;
}
.custom-alert {
  padding: 25px;
  border-radius: 10px;
}
.custom-alert h4 {
  font-size: 20px;
  font-weight: bold;
  color: #1a1a1a;
}
.custom-alert p {
  font-size: 1.231rem;
  font-weight: 500;
  color: #1a1a1a;
}
// Kyc process
.kyc-page {
  height: 100vh;
  overflow: auto;
}
.stepper-item.current {
  display: block;
}
.kyc-content-footer {
  border-top: 0.5px solid #e0e0df;
  background-color: #fff;
  position: absolute;
  bottom: 0%;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.stepper-item {
  display: none;
}
.custom-stepper.stepper.stepper-links .stepper-nav .stepper-item:after {
  display: none;
}
.custom-stepper.stepper.stepper-links
  .stepper-nav
  .stepper-item.current
  .stepper-title {
  color: #7c7c7c;
  margin-bottom: 0px;
}
.kyc-process label {
  font-size: 1.231rem;
  font-weight: 500;
  text-align: left;
}
.border-r5px {
  border-radius: 0.313rem !important;
}
.border-r6px {
  border-radius: 0.462rem !important;
}
.border-r8px {
  border-radius: 0.615rem !important;
}
.border-r10px {
  border-radius: 0.769rem !important;
}
.border-r15px {
  border-radius: 1.154rem !important;
}
.border-r23px {
  border-radius: 1.769rem !important;
}
.error-icon {
  border-radius: 3.125rem !important;
  border: 2px solid #ffff;
  background-color: #ffff;
}
.btnPrev,
.prevtab {
  padding: 1.231rem 32px;
  min-width: 114px;
  height: 50px;
  font-size: 1.231rem;
  font-weight: bold;
  color: #1b74e4 !important;
  background-color: #e7f1fd !important;
  margin-right: -8px;
  border-radius: 8px 0px 0px 8px;
  position: sticky;
  z-index: 0;
}
.btnNext,
.nexttab {
  padding: 1.231rem 32px;
  min-width: 114px;
  height: 50px;
  font-size: 1.231rem;
  font-weight: bold;
  color: #ffff !important;
  background-color: #1b74e4 !important;
  border-radius: 8px;
}
.pills-radius {
  border-radius: 30px;
}
// Width
.w-fit-content {
  width: fit-content !important;
}
.btn.btn-primary.btnEdit:hover:not(.btn-active) {
  // width: 124px;
  height: 50px;
  padding: 15px 22px;
  border-radius: 8px;
  background-color: #f9f9f9 !important;
}
.btnEdit {
  // width: 124px;
  height: 50px;
  padding: 15px 22px;
  border-radius: 8px;
  background-color: #f9f9f9 !important;
}
// .image-input.image-input-circle.upload-profile [data-kt-image-input-action=change] {
//   top: auto;
//   bottom: 0;
//   transform: translate(-140%, 0);
// }
// .image-input.upload-profile [data-kt-image-input-action=change] {
//   bottom: 0;
// }
.btn-table {
  font-size: 1.077rem;
  font-weight: 600;
  min-height: 40px;
}
.bgi-contain {
  background-size: contain !important;
}
.close-top {
  position: absolute;
  top: -3%;
  right: -3%;
}
// Custom Modal
.modal-content {
  border-radius: 10px;
}
.close-inner-top {
  position: absolute;
  top: -1%;
  right: -1%;
  width: 35px;
  height: 35px;
}
.close-inner-top-2 {
  position: absolute;
  // top: -1%;
  // right: -1%;
  width: 35px;
  height: 35px;
  top: 0;
  right: 0px;
  margin: 8px 10px 0px;
}
.close-inner-top-3 {
  position: absolute;
  width: 35px;
  height: 35px;
  top: 0;
  right: 0px;
  margin: 8px 10px 0px;
  position: absolute;
  top: 0;
  right: 0px;
  margin: 8px 10px 0px;
}
@media (min-width: 575px) {
  .close-inner-top-3 {
    top: 2%;
    left: 1.5%;
  }
  .close-inner-top-2 {
    top: 4%;
    left: 3.5%;
  }
  .close-inner-top {
    top: 7%;
    left: 3%;
  }
  .close-inner-top-2 {
    position: absolute;
    top: 4%;
    left: 3.5%;
  }
  .close-inner-top {
    position: absolute;
    top: 7%;
    left: 3%;
  }
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
@media (min-width: 992px) {
  .app-sidebar-toggle {
    box-shadow: none !important;
    background-color: none !important;
  }
}
.app-header {
  border-bottom: solid 0.5px #e0e0df;
  box-shadow: none;
}
.app-wrapper {
  background-color: #ffff;
}
// SideMenu Custom CSS
.app-sidebar-menu {
  background-color: #131313 !important;
}
[data-kt-app-layout='dark-sidebar']
  .app-sidebar
  .menu
  .menu-item
  .menu-link.active
  .menu-title,
[data-kt-app-layout='dark-sidebar']
  .app-sidebar
  .menu
  .menu-item.here
  > .menu-link
  .menu-title,
[data-kt-app-layout='dark-sidebar']
  .app-sidebar
  .menu
  .menu-item
  .menu-link
  .menu-title {
  font-size: 1.231rem;
  font-weight: 500;
}
[data-kt-app-layout='dark-sidebar']
  .app-sidebar
  .menu
  .menu-item
  .menu-link.active {
  background-color: #1e1f26;
}
[data-kt-app-layout='dark-sidebar']
  .app-sidebar
  .menu
  .menu-item.show
  > .menu-link
  .menu-icon,
[data-kt-app-layout='dark-sidebar']
  .app-sidebar
  .menu
  .menu-item.show
  > .menu-link
  .menu-icon
  .svg-icon,
[data-kt-app-layout='dark-sidebar']
  .app-sidebar
  .menu
  .menu-item.show
  > .menu-link
  .menu-icon
  i {
  color: #ffff !important;
  fill: #ffff !important;
}
// Line Clamp & Truncate
.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 50rem;
}
.truncate-2 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
}
// Categories Custom
.categories-card {
  padding: 0px;
  .card-header {
    padding: 0px 1.5rem;
  }
  .accordion-item {
    border-bottom: solid 0.5px #e0e0df;
    border-left: solid 0px #e0e0df;
    border-right: solid 0px #e0e0df;
  }
  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .accordion-item:last-of-type .accordion-collapse,
  .accordion-item:last-of-type .accordion-button.collapsed,
  .accordion-item:last-of-type {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border: none;
  }
  .accordion-button {
    max-height: 58px;
    background-color: #f9f9f9;
  }
  .accordion-button::after {
    width: 17px;
    height: 9px;
    background-image: url('../../../assets/media/svg_uTrade/down-arrow.svg');
  }
  .accordion-button:not(.collapsed) {
    background-color: #f9f9f9;
    box-shadow: none;
  }
  .accordion-header {
    background-color: #f9f9f9 !important;
    border-bottom: 0.5px solid #e0e0df;
  }
  .accordion-header-white {
    background-color: #fff !important;
    border-bottom: 0.5px solid #e0e0df;
  }
  .accordion-button-white {
    background-color: #fff !important;
    max-height: 58px;
  }
  .accordion-button-white::after {
    display: none;
  }
}
.sub-categories-cross {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.group-categories-cross {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.group-categories-cross.bottom {
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.responsive-closed {
  position: relative;
  left: -28px;
  top: -25px;
}
.responsive-closed-icon-closed {
  position: absolute;
}
// Custom Line heights
.lh-1n2 {
  line-height: 1.2 !important;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #7c7c7c #f9f9f9;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-track {
  background: #f9f9f9;
}
*::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 10px;
  border: 1px solid #ffffff;
}
::-webkit-scrollbar-thumb:horizontal {
  background-color: #c1c1c1;
  border: none;
}
::-webkit-scrollbar-track:horizontal {
  background: none;
}
// business certificate
.biz-certificate {
  border: solid 0.5px #e0e0df;
  border-radius: 10px;
  .table:not(.table-bordered) th {
    font-size: 1.231rem;
    font-weight: 600;
  }
  .table:not(.table-bordered) td {
    font-size: 1.154rem;
    font-weight: 500;
  }
  .custom-badge {
    font-size: 15px;
    font-weight: 600;
    padding: 10px 15px;
    margin-right: 8px;
    margin-bottom: 8px;
    color: #1a1a1a;
    border-radius: 23px;
  }
  // .table:not(.table-bordered) th,
  .table:not(.table-bordered) tr,
  .table:not(.table-bordered) td {
    border-bottom: solid 0.5px #e0e0df;
    color: #1a1a1a;
  }
  .table tbody tr:last-child {
    border-color: transparent;
    border-width: 0;
    border-style: none;
  }
}
.linked-categories {
  // .table:not(.table-bordered) th,
  .table:not(.table-bordered) tr,
  .table:not(.table-bordered) td {
    border-bottom: solid 0.5px #e0e0df;
    color: #1a1a1a;
  }
  .table:not(.table-bordered) tr {
    padding-left: 30px;
  }
  .table tbody tr:last-child {
    border-color: transparent;
    border-width: 0;
    border-style: none;
  }
}
.custom-form {
  label {
    font-size: 1.231rem;
    font-weight: 500;
    color: #1a1a1a;
  }
  .form-control-custom {
    border: solid 0.5px #e0e0df;
    background-color: #ffff;
  }
}
.custom-form-light {
  label {
    font-size: 1.231rem;
    font-weight: 500;
    color: #1a1a1a;
  }
  .form-control-custom {
    border: solid 0.5px #e0e0df;
    background-color: #e0e0df;
    font-size: 1.231rem;
    font-weight: 600;
    color: #1a1a1a;
  }
}
// Custom Tab Container
.custom-tabContainer {
  .nav {
    --bs-nav-link-padding-x: 1.5rem;
    --bs-nav-link-padding-y: 0.8rem;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #ffff;
  }
  .nav-item .nav-link {
    font-size: 1.231rem;
    font-weight: bold;
    color: #7c7c7c;
    display: inline-flex;
    align-items: center;
  }
}
.nav-item .check-after::after {
  content: '\f00c';
  font-family: FontAwesome;
  display: inline-block;
  padding-left: 6px;
  vertical-align: baseline;
  font-size: 18px;
}
.nav-item .error::after {
  color: #e55451;
  content: '\f06a';
  font-family: FontAwesome;
  display: inline-block;
  padding-left: 6px;
  vertical-align: baseline;
  font-size: 18px;
}
.dimension.error::after {
  color: #e55451;
  content: '\f06a';
  font-family: FontAwesome;
  display: inline-block;
  padding-left: 6px;
  vertical-align: baseline;
  font-size: 18px;
}
@media (min-width: 992px) {
  .btn-lg {
    height: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}
// Line clamp
.line-clamp-1 {
  // max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
:root {
  color-scheme: only light;
}
.error-bg {
  background: rgb(255, 141, 0);
  background: linear-gradient(
    200deg,
    rgba(255, 141, 0, 1) 0%,
    rgba(240, 139, 15, 0.896796218487395) 14%,
    rgba(27, 116, 228, 1) 63%
  );
}
@media (max-width: 768px) {
  .error-bg {
    background: rgb(255, 141, 0);
    background: linear-gradient(
      386deg,
      rgb(255, 141, 0) 0%,
      rgba(240, 139, 15, 0.8967962185) 14%,
      rgb(27, 116, 228) 63%
    );
  }
}
.form-check.form-check-solid .form-check-input:not(:checked) {
  background-color: #efefef !important;
}
.bg-gray-dark {
  background-color: #efefef !important;
}
.close-top-2 {
  position: absolute;
  top: -6%;
  right: -6%;
  width: 30px;
}
@media (min-width: 992px) {
  .close-top-2 {
    position: absolute;
    top: -6%;
    right: -6%;
    width: 35px;
  }
}
.nav-line-tabs.nav-line-tabs-2x .nav-item .nav-link.active,
.nav-line-tabs.nav-line-tabs-2x .nav-item.show .nav-link,
.nav-line-tabs.nav-line-tabs-2x .nav-item .nav-link:hover:not(.disabled) {
  border-bottom-width: 3px;
}
// MAKE FORM CONTROL WHITE ON FOCUS
.form-control-custom:focus.bg-white {
  background-color: #ffff !important;
}
.form-check-input.form-check-success:checked {
  background-color: #4cc320;
  border-color: #4cc320;
}
.edit-bottom {
  position: absolute;
  bottom: -6%;
  right: -6%;
}
.edit-top {
  position: absolute;
  top: -6%;
  right: -6%;
}
.custom-badge {
  font-size: 1.154rem;
  font-weight: 600;
  padding: 10px 15px;
  margin-right: 8px;
  margin-bottom: 8px;
  color: #1a1a1a;
  border-radius: 23px;
}
// Background Colors -----------------------------------------------------------------------------------------
.bg-fbe5e5 {
  background-color: #fbe5e5 !important;
}
.border-bottom-table tr {
  border-bottom: 0.5px solid #e0e0df !important;
}
// HIDE INPUT NUMBER CONTROL
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
.bg-d4e1fc {
  background-color: #d4e1fc !important;
}
.bg-ccebfd {
  background-color: #ccebfd !important;
}
.bg-c9f3d7 {
  background-color: #c9f3d7 !important;
}
.bg-fbeace {
  background-color: #fbeace !important;
}
.bg-c6e4fb {
  background-color: #c6e4fb !important;
}
.bg-d5d5f2 {
  background-color: #d5d5f2 !important;
}
.bg-ccf3f0 {
  background-color: #ccf3f0 !important;
}
.bg-fbebbc {
  background-color: #fbebbc !important;
}
.bg-efefef {
  background-color: #efefef !important;
}
.bg-fcd00d {
  background-color: #fcd00d !important;
}
.bg-e5f6de {
  background-color: #e5f6de !important;
}
.bg-fff4d9 {
  background-color: #fff4d9 !important;
}
.btn-table {
  font-size: 1.077rem !important;
  font-weight: 600 !important;
  min-height: 40px !important;
}
.bg-e7f1fd {
  background-color: #e7f1fd !important;
}
.text-7c7c7c {
  color: #7c7c7c !important;
}
.custom-timeline {
  .timeline .timeline-line {
    border-left-width: 3px;
  }
  .symbol .symbol-label {
    background-color: #efefef;
  }
  .timeline-item.active {
    .timeline-line {
      border-left-color: #4fbf26;
    }
    .symbol .symbol-label {
      color: #ffff;
      background-color: #4fbf26;
    }
  }
  .timeline-item.cancel {
    .timeline-line {
      border-left-color: #e55451;
    }
    .symbol .symbol-label {
      color: #ffff;
      background-color: #e55451;
    }
  }
  .timeline-item.move {
    .timeline-line {
      border-left-color: #ff8400;
    }
    .symbol .symbol-label {
      color: #ffff;
      background-color: #ff8400;
    }
  }
}
.timeline-short-alert {
  top: 0;
  right: 0;
  border-radius: 0px 10px 0px 10px;
  background-color: #efefef;
}
.text-gary {
  color: #7c7c7c !important;
}
.fill-danger {
  color: #e55451 !important;
}
.card {
  border-radius: 10px;
  border: solid 0.5px #e0e0df;
}
// --------------------------------------- DON'T MODIFY --------------------------------------- //
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
/* FOR DATA TABLE  */
.table:not(.table-bordered).table-row-bordered.datatable tbody tr:first-child {
  border-top: 1px solid #e0e0df;
  border-collapse: separate;
}
.table:not(.table-bordered).table-row-bordered.datatable tbody tr:last-child {
  border-bottom: none;
  border-collapse: separate;
}
.border-rtb0px {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.custom_dropdown {
  #nav-dropdown {
    text-decoration: none !important;
    border-bottom: none;
  }
}
.bg-d4e1fc {
  background-color: #d4e1fc !important;
}
.bg-ccebfd {
  background-color: #ccebfd !important;
}
.bg-ccf3f0 {
  background-color: #ccf3f0 !important;
}
.bg-fbebbc {
  background-color: #fbebbc !important;
}
.bg-f9f9f9 {
  background-color: #f9f9f9 !important;
}
.bg-fff4d9 {
  background-color: #fff4d9 !important;
}
.bg-e5f6de {
  background-color: #e5f6de !important;
}
.btn-table {
  font-size: 1.077rem !important;
  font-weight: 600 !important;
  min-width: max-content;
}
.form-label {
  color: #1a1a1a;
}
.add-new-order {
  .nav-link {
    border-radius: 23px;
    font-weight: 600;
    background-color: #efefef;
    color: #7c7c7c;
  }
}
.input-group {
  .form-control:focus {
    border: solid 0.5px #e0e0df;
  }
}
// STEPPER CSS
.stepperInput {
  display: flex;
  .stepperInput__input {
    border-left: 0;
    border-right: 0;
    width: 55px;
    height: 40px;
    text-align: center;
    border: solid 0.5px #e0e0df;
  }
  .button {
    cursor: pointer;
    padding: 5px 15px;
    color: #ffffff;
    background-color: #1b74e4;
    font-size: 1.231rem;
    font-weight: bolder;
    border: none;
    border-radius: 7px;
  }
  .button--addOnLeft {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .button--addOnRight {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input {
    border: solid 0.5px #e0e0df;
    border-radius: 0;
    color: #1a1a1a;
  }
}
// Custom Carousel
.carousel-custom .carousel-indicators.carousel-indicators-dots li.active:after {
  background-color: #7c7c7c !important;
}
.carousel-custom .carousel-indicators.carousel-indicators-dots li:after {
  background-color: #efefef !important;
}
.breakWord-nextLine {
  white-space: normal !important; /* Allow text to wrap */
  word-wrap: break-word; /* Break word if it exceeds column width */
}
